import { APIBase } from "../../index";
import axios from "axios";

const CONFIRM_LOGIN_URL = "User/ResetPassword";

export const ResetPassword = (Username) => {
  const form = new FormData();
  form.append("Username", Username);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  let request = axios.post(APIBase + CONFIRM_LOGIN_URL, form, config);

  request.catch((err) => {
    console.log(err);
  });
  return request;
};
