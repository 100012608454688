export const SET_BRANCH_DATA = 'SET_BRANCH_DATA';
export const SET_VIEW_BRANCH_LSIT = 'SET_VIEW_BRANCH_LSIT';

export const setBranchData = (data) => {
  return {
    type: SET_BRANCH_DATA,
    payload: data
  };
};



export const setViewBranchList = (view) => {
  return {
    type: SET_VIEW_BRANCH_LSIT,
    payload: view
  };
};

