import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import axios from "axios";
import { getSubdomain } from "../../_metronic/utils/utils";

export const AxiosContext = createContext({
  headers: {},
  setHeaders: () => {},
  axios: () => {},
});
export const AxiosProvider = ({ children }) => {
  const [headers, setHeaders] = useState({});
  useEffect(() => {
    axios.defaults.headers.common["x-tenant-identity"] = 'amar';
    axios.defaults.headers.common["x-user-type"] = "store";
    axios.defaults.headers.common["language"] = "1";
    for (let index in headers) {
      axios.defaults.headers.common[index] = headers[index];
    }
  }, [headers]);
  return (
    <AxiosContext.Provider
      value={{
        headers,
        setHeaders,
        axios,
      }}
    >
      {children}
    </AxiosContext.Provider>
  );
};
export const useAxios = () => useContext(AxiosContext);
