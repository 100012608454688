import { APIBase } from "../../index";
import axios from "axios";
import { setupAxios } from "../../../../_metronic";
import store from "../../../../app/store/store";

const URL = "Store/Roles/UpdateRole";

export const Edit = (data) => {
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  setupAxios(axios, store);

  let request = axios.put(APIBase + URL , data,config);

  request.catch((err) => {
    console.log(err);
  });
  return request;
};
