import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { IdentityAPI } from "../../API";
import Yup from "../../utilities/Yup";

const FormSchema = Yup.object().shape({
  ConfirmCode: Yup.string().required("يرجى ادخال كود التاكيد"),
  Password: Yup.string()
    .min(6, "يجب الا تقل كلمة المرور عن 6 رموز")
    .required("يرجى ادخال كلمة المرور")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
      "يجب أن لا تقل كلمة المرور عن 6 أحرف وان تحتوى على الاقل على ،حرف كبير ، حرف صغير ، رقم واحد وحرف خاص واحد"
    ),
  PasswordConfirm: Yup.string().oneOf(
    [Yup.ref("Password"), null],
    "كلمة المرور غير مطابقة"
  ),
});

class ConfirmLogin extends Component {
  state = { isRequested: false };

  render() {
    const { isRequested } = this.state;
    if (isRequested) {
      return <Redirect to="/auth" />;
    }

    return (
      <div
        style={{ direction: "rtl" }}
        className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper"
      >
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <h3>استرجاع كلمة المرور</h3>
            </div>

            <Formik
              initialValues={{
                ConfirmCode: "",
                Password: "",
                PasswordConfirm: "",
              }}
              validationSchema={FormSchema}
              validate={(values) => {
                const errors = {};

                if (!values.ConfirmCode) {
                  errors.ConfirmCode = "يرجى ادخال كود التاكيد";
                }

                return errors;
              }}
              onSubmit={async (values, { setStatus, setSubmitting }) => {
                setSubmitting(true);

                await IdentityAPI.User.ConfirmResetPassword(
                  this.props.history.location.state.Username,
                  values.Password,
                  values.ConfirmCode
                )
                  .then((s) => {
                    setSubmitting(false);

                    this.props.history.push("/auth/login");
                  })
                  .catch((e) => {
                    setStatus("كود التاكيد غير صحيح");
                    setSubmitting(false);
                    // ErrorHandler(e, setErrors);
                  });
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} className="kt-form">
                  {status && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  )}

                  <div className="form-group">
                    <TextField
                      type="number"
                      label="كود التاكيد"
                      margin="normal"
                      fullWidth={true}
                      name="ConfirmCode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ConfirmCode}
                      helperText={touched.ConfirmCode && errors.ConfirmCode}
                      error={Boolean(touched.ConfirmCode && errors.ConfirmCode)}
                    />
                  </div>

                  <div className="form-group">
                    <TextField
                      type="password"
                      label="كلمة المرور الجديدة"
                      margin="normal"
                      fullWidth={true}
                      name="Password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Password}
                      helperText={touched.Password && errors.Password}
                      error={Boolean(touched.Password && errors.Password)}
                    />
                  </div>

                  <div className="form-group">
                    <TextField
                      type="password"
                      label="تاكيد كلمة المرور الجديدة"
                      margin="normal"
                      fullWidth={true}
                      name="PasswordConfirm"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.PasswordConfirm}
                      helperText={
                        touched.PasswordConfirm && errors.PasswordConfirm
                      }
                      error={Boolean(
                        touched.PasswordConfirm && errors.PasswordConfirm
                      )}
                    />
                  </div>

                  <div className="kt-login__actions">
                    <Link to="/auth/forgot-password">
                      <button
                        type="button"
                        className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                      >
                        رجوع
                      </button>
                    </Link>

                    <button
                      type="submit"
                      className="btn btn-primary btn-elevate kt-login__btn-primary"
                      disabled={isSubmitting}
                    >
                      تاكيد
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(connect(null, auth.actions)(ConfirmLogin));
