import { APIBase } from "../../index";
import axios from "axios";
import { setupAxios } from "../../../../_metronic";
import store from "../../../../app/store/store";

const URL = "SuperAdmin/Users/GetAllUsers";

export const List = (
  Search = {},
  Filters = [],
  Order = [],
  Start = 0,
  Length = 25
) => {
  var params = {
    Columns: [],
    Search,
    Filters,
    Order,
    Start,
    Length,
    draw: 0,
  };

  const config = {
    headers: {
      "content-type": "application/json",
    },
  };

  setupAxios(axios, store);
  let request = axios.post(APIBase + URL, params, config);

  request.catch((err) => {
    console.log(err?.response?.data);
  });
  return request;
};
