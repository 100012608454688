import { APIBase } from "../../index";
import axios from "axios";
import { setupAxios } from "../../../../_metronic";
import store from "../../../store/store";

const URL = "SuperAdmin/Users/EditUser";

export const Edit = ( data) => {
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  setupAxios(axios, store);

  let request = axios.put(APIBase + URL, data, config);
  return request;
};
