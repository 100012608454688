import React from "react";
import UserNotifications from "../../../app/partials/layout/UserNotifications";
import UserProfile from "../../../app/partials/layout/UserProfile";
import { toAbsoluteUrl } from "../../utils/utils";
import { ReactComponent as MneuIcon } from "../../../_metronic/layout/assets/layout-svg-icons/mneu.svg";

export default class Topbar extends React.Component {
  render() {
    return (
      <div className="kt-header__topbar">
        {/* <SearchDropdown useSVG="true" />

        */}
        <span
          className={"kt-svg-icon layout__mneuIcon"}
          style={{
            width: "35px",
            height: "35px",
            margin: "7px",
            position: "absolute",
            right: "0px",
            cursor: "pointer",
          }}
          onClick={() => {
            this.props.toggle_kt_aside && this.props.toggle_kt_aside();
          }}
        >
          <MneuIcon style={{ width: "35px", height: "35px" }} />
        </span>
        

        <UserProfile showAvatar={true} showHi={true} showBadge={false} />

        <UserNotifications
          bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
          pulse="true"
          pulseLight="false"
          skin="dark"
          iconType=""
          icon="alert"
          type="success"
          useSVG="false"
          dot="false"
        />

       
        {/* 
        <QuickActionsPanel
          bgImage={toAbsoluteUrl("/media/misc/bg-2.jpg")}
          skin="dark"
          iconType=""
          useSVG="true"
          gridNavSkin="light"
        /> */}

        {/* <MyCart
          iconType=""
          useSVG="true"
          bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
        /> */}

        {/* <QuickPanelToggler /> */}

        {/* <LanguageSelector iconType="" /> */}
      </div>
    );
  }
}
