const Permissions = {
  Core: {
    App: {
      AppMenus: "Permissions.Core.App.AppMenus",
      AppSettings: "Permissions.Core.App.AppSettings",
    },
   
   
    ContactUs: {
      View: "Permissions.Core.ContactUs.View",
      Archive: "Permissions.Core.ContactUs.Archive",
    },
  },
  
  Identity: {
    Users: {
      View: "Permissions.Identity.Users.View",
      Create: "Permissions.Identity.Users.Create",
      Edit: "Permissions.Identity.Users.Edit",
      Delete: "Permissions.Identity.Users.Delete",
      ChangePassword: "Permissions.Identity.Users.ChangePassword",
      ChangeStatus  : "Permissions.Identity.Users.ActivationStatus",
    },
    Roles: {
      View: "Permissions.Identity.Roles.View",
      Create: "Permissions.Identity.Roles.Create",
      Edit: "Permissions.Identity.Roles.Edit",
      Delete: "Permissions.Identity.Roles.Delete",
    },
  },

  
  Messaging: {
  
    Notifications: {
      View: "Permissions.Messaging.Notifications.View",
      Create: "Permissions.Messaging.Notifications.Create",
      Edit: "Permissions.Messaging.Notifications.Edit",
      Delete: "Permissions.Messaging.Notifications.Delete",
    },
  },
  Store : {
    ViewAll : "Permissions.Store.ViewAll"  , 
    Details: "Permissions.Store.Details",
    Create: "Permissions.Store.Create",
    Edit: "Permissions.Store.Edit",
    Delete:  "Permissions.Store.Delete",
    ChangeActiveStatus : "Permissions.Store.ActivationStatus"
  },
  StoreBranch : {
    ViewAll : "Permissions.StoreBranch.ViewByTenant"  , 
    Details: "Permissions.StoreBranch.Details",
    Create: "Permissions.StoreBranch.Create",
    Edit: "Permissions.StoreBranch.Edit",
    Delete:  "Permissions.StoreBranch.Delete",
    ChangeActiveStatus : "Permissions.StoreBranch.SetActivationStatus"
  },
  Discount : {
    ViewAll : "Permissions.Discount.ViewAll"  , 
    ViewByTenant : "Permissions.Discount.ViewByTenant"  , 
    Details: "Permissions.Discount.Details",
    Create: "Permissions.Discount.Create",
    Edit: "Permissions.Discount.Edit",
    Delete:  "Permissions.Discount.Delete",
  },

  DiscountTransaction : {
    ViewAll : "Permissions.DiscountTransaction.ViewTransascionsByTenant"  , 
    Confirm : "Permissions.DiscountTransaction.ConfirmTransaction"  , 
  },
 
 
};

export default Permissions;


// [
//   "Permissions.Store.Details",
//   "Permissions.Identity.Roles.Edit",
//   "Permissions.Identity.Roles.Create",
//   "Permissions.Identity.Roles.View",
//   "Permissions.Discount.Create",
//   "Permissions.Discount.Delete",
//   "Permissions.Discount.ViewByTenant",
//   "Permissions.Discount.Edit",
//   "Permissions.Discount.Details",
//   "Permissions.StoreBranch.Details",
//   "Permissions.StoreBranch.Create",
//   "Permissions.StoreBranch.Edit",
//   "Permissions.StoreBranch.Delete",
//   "Permissions.StoreBranch.ViewByTenant",
//   "Permissions.StoreStaff.Details",
//   "Permissions.StoreStaff.Edit",
//   "Permissions.StoreStaff.Delete",
//   "Permissions.StoreStaff.Create",
//   "Permissions.StoreStaff.ViewByTenant",
//   "Permissions.Store.Edit",
//   "Permissions.Identity.Roles.Delete",
//   "Permissions.Discount.ViewAll",
//   "Permissions.StoreBranch.ViewAll",
//   "Permissions.StoreBranch.SetActivationStatus",
//   "Permissions.DiscountTransaction.ViewTransascionsByTenant",
//   "Permissions.DiscountTransaction.ConfirmTransaction"
// ]

