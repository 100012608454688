import React, { lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import CrumbRoute from "../../router/crumb-route";
import SettingsMenu from "./settingsMenu/settingsMenu";

const Permissions = lazy(() => import("./Permissions/index"));
const Users = lazy(() => import("./Users/index"));
const store = lazy(() => import("./Store/Edit/index"))
export default function SettingsPage() {
  // useEffect(() => {
  //   console.log('Home page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Switch>
      <Redirect exact={true} from="/settings" to="/settings/list" />
      <Route path="/settings/list" component={SettingsMenu} />

      <CrumbRoute
        title="مجموعات الصلاحيات"
        path="/settings/Permissions"
        component={Permissions}
      />

      <CrumbRoute
        title="إدارة المستخدمين"
        path="/settings/users"
        component={Users}
      />

      <CrumbRoute
        title="تعديل بيانات المتجر"
        path="/settings/store/edit"
        component={store}
      />
    </Switch>
  );
}
