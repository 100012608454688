import * as IdentityAPI from "./Identity";
import * as StoreAPI from './Stores';
import * as DiscountCodesAPI from './DiscountCodes';
import * as DiscountStoreBranchesAPI from './DiscountStoreBranches';

import { ErrorHandler, _RenderErrors } from "./ErrorHandler";

//Local
//export const IdentityAPIBase = "https://localhost:44397/";
//export const BaseURL = "http://localhost:5540/";
//export const APIBase = "http://localhost:5540/api/";

//Test
//export const IdentityAPIBase = "https://identity.silh.net/";
//export const BaseURL = "https://storeapi.silh.net/";
//export const APIBase = "https://storeapi.silh.net/api/v1/";

//Live
export const IdentityAPIBase = "https://identity.silh.net/";
export const BaseURL = "https://storeapi.silh.net/";
export const APIBase = "https://storeapi.silh.net/api/v1/";

export {
  IdentityAPI,
  ErrorHandler,
  _RenderErrors,
  DiscountCodesAPI,
  StoreAPI,
  DiscountStoreBranchesAPI
};
