import React, { createContext, useContext } from "react";

const colors = {
  primary: "#21294D",
  primaryInvert: "white",
  gray: "rgb(153, 153, 153)",
  Secondary: "#21294D",
  bgActive: "#C4C5D61A",
  grayBackground: "#F8F8F8",
  accepted: "#4A9A3A",
  rejected: "#fd397a",
  canceled: "#270A0A",
};

const fonts = {
  regular: "TheSans-Plain",
  bold: "TheSans-Bold",
};
export const LayoutContext = createContext({
  colors: colors,
  fonts: fonts,
});

export const LayoutProvider = ({ children }) => {
  return (
    <LayoutContext.Provider
      value={{
        colors,
        fonts,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => useContext(LayoutContext);
