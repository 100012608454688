import React, { useState } from "react";
import LoginPage from "./LoginPage";
import ConfirmLogin from "./ConfirmLogin";


export default (props) => {
  const [pageStatus, setPage] = useState(0);
  const [pageData, setPageData] = useState(null);
  

  if(pageStatus==0)
  return <LoginPage {...props} setPage={setPage} setPageData={setPageData} />
  
  if(pageStatus==1)
  return <ConfirmLogin {...props} setPage={setPage} setPageData={setPageData} pageData={pageData} />
};
