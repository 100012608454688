import React, { lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CrumbRoute from "../../router/crumb-route";

const CRUD = lazy(() => import("./crud/index"));



export default function MembersPage() {
  // useEffect(() => {
  //   console.log('Home page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Switch>
      <Redirect exact={true} from="/discounts" to="/discounts/crud/list" />
      <CrumbRoute title="قائمة الخصومات" path="/discounts/crud" component={CRUD} />

    </Switch>
  );
}
