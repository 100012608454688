import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik, ErrorMessage } from "formik";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { IdentityAPI, ErrorHandler, _RenderErrors } from "../../API";
import Yup from "../../utilities/Yup";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { toAbsoluteUrl } from "../../../_metronic";
const SignInSchema = Yup.object().shape({
  Username: Yup.string().required("يرجى ادخال اسم المستخدم"),
  Password: Yup.string()
    .min(6, "يجب الا تقل كلمة المرور عن 6 رموز")
    .required("يرجى ادخال كلمة المرور")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
      "يجب أن لا تقل كلمة المرور عن 6 أحرف وان تحتوى على الاقل على ،حرف كبير ، حرف صغير ، رقم واحد وحرف خاص واحد"
    ),
});
const Login = (props) => {
  const { history } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    width: "100%",
    paddingRight: "2.5rem",
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ width: "100%", paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ width: "100%", paddingRight: "2.5rem" });
  };

  return (
    <>
      <div style={{ direction: "rtl" }} className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title" style={{ 
             display: 'flex',
             flexDirection: 'column',  
             justifyContent: 'center',
             alignItems: 'center',
             
             }}>
          <img
            alt="logo"
            src={toAbsoluteUrl(`/media/logos/logo.svg`)}
            style={{ width: "50px" }}
          />
            <h3>
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
          </div>

          <Formik
            initialValues={{
              Username: "",
              Password: "",
              showPassword: false,
            }}
            validationSchema={SignInSchema}
            validate={(values) => {
              const errors = {};

              if (!values.Username) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.Username = "يرجى ادخال اسم المستخدم";
              }
              //  else if (
              //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              // ) {
              //   errors.email = intl.formatMessage({
              //     id: "AUTH.VALIDATION.INVALID_FIELD",
              //   });
              // }

              if (!values.Password) {
                errors.Password = "يرجى ادخال كلمة المرور";
              }

              return errors;
            }}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              enableLoading();
              await IdentityAPI.User.Login(values.Username, values.Password)
                .then(({ data }) => {
                  disableLoading();
                  debugger;
                  props.setPage(1)
                  props.setPageData({
                    Username: values.Username,
                    resend_token: data.resend_token,
                  })
                  if (data?.verify_token) alert(data?.verify_token);
                })
                .catch((e) => {
                  debugger;
                  setStatus("اسم المستخدم او كلمة المرور غير صحيحة");
                  disableLoading();
                  setSubmitting(false);
                  // ErrorHandler(e, setErrors);
                });

              //let result = await Identity.User.UserLogin("test", "test");
              // console.log(result);
              // enableLoading();
              // setTimeout(() => {
              //   login(values.email, values.password)
              //     .then(({ data: { accessToken } }) => {
              //       disableLoading();
              //       props.login(accessToken);
              //     })
              //     .catch(() => {
              //       disableLoading();
              //       setSubmitting(false);
              //       setStatus(
              //         intl.formatMessage({
              //           id: "AUTH.VALIDATION.INVALID_LOGIN",
              //         })
              //       );
              //     });
              // }, 1000);
            }}
          >
            {({
              values,
              setFieldValue,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => {
              return (
                <form
                  noValidate={true}
                  autoComplete="off"
                  className="kt-form"
                  onSubmit={handleSubmit}
                >
                  {status ? (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  ) : null
                  // <div role="alert" className="alert alert-info">
                  //   <div className="alert-text">
                  //     Use account <strong>admin@demo.com</strong> and password{" "}
                  //     <strong>demo</strong> to continue.
                  //   </div>
                  // </div>
                  }

                  <div className="form-group">
                    <TextField
                      type="text"
                      label="اسم المستخدم"
                      margin="normal"
                      className="kt-width-full"
                      name="Username"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Username}
                      helperText={
                        touched.Username && _RenderErrors(errors.Username)
                      }
                      error={Boolean(touched.Username && errors.Username)}
                    />
                  </div>

                  <div className="form-group">
                    <TextField
                      type={values.showPassword ? "text" : "password"}
                      margin="normal"
                      label="كلمة المرور"
                      className="kt-width-full"
                      name="Password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Password}
                      helperText={
                        touched.Password && _RenderErrors(errors.Password)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setFieldValue(
                                  "showPassword",
                                  !values.showPassword
                                );
                              }}
                              edge="end"
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(touched.Password && errors.Password)}
                    />
                  </div>

                  <Link
                    to="/auth/forgot-password"
                    className="kt-link kt-login__link-forgot"
                  >
                    <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                  </Link>
                  <div className="kt-login__actions">
                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                        }
                      )}`}
                      style={loadingButtonStyle}
                    >
                      دخول
                    </button>

                    <ErrorMessage render={<div>test</div>} component="div" />
                  </div>
                </form>
              );
            }}
          </Formik>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: "15px",
              color: "#370b0b",
            }}
          >
            جميع الحقوق محفوظة لموقع صلة
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(
  ({ auth }) => ({ login: auth.login }),
  auth.actions
)(Login);
