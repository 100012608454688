import React from "react";

function Icon({ fill, size = 11 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill={fill}
      style={{
        width: size,
        height: size,
      }}
    >
      <g data-name="Group 13849" transform="translate(-383.538 -1560.397)">
        <path
          d="M393.633 1563.715v1.463l-.023-.006.019.4a.757.757 0 00.723.724h.035a.764.764 0 00.759-.738v-1.845a1.754 1.754 0 001.035-1.594 1.791 1.791 0 00-3.58.015 1.741 1.741 0 001.032 1.581zm.756-2.081a.5.5 0 11-.5.5.5.5 0 01.5-.5z"
          data-name="Path 19483"
          transform="translate(.083)"
        ></path>
        <path
          d="M388.555 1570.073h-.078a1.973 1.973 0 00-1.968 1.937v.01a1.97 1.97 0 001.954 1.935h.129a1.942 1.942 0 00-.036-3.883zm.56 1.99a.61.61 0 01-.193.429.58.58 0 01-.439.165.611.611 0 01-.592-.632.606.606 0 01.623-.589h.009a.611.611 0 01.593.631z"
          data-name="Path 19484"
          transform="translate(0 .505)"
        ></path>
        <path
          d="M397.781 1566.037a1.859 1.859 0 00.983.282h.031a1.747 1.747 0 10.025-3.494h-.048a1.754 1.754 0 00-1.795 2.019 7.2 7.2 0 00-2.376 2.774 7.259 7.259 0 00-2.4-2.789 1.712 1.712 0 00.019-.264 1.81 1.81 0 00-1.749-1.723 1.742 1.742 0 10-.093 3.477.178.178 0 00.032 0 1.853 1.853 0 001-.293 5.592 5.592 0 012.392 4.165.75.75 0 00.792.654h.008l.037-.3.026.3h.007a.725.725 0 00.729-.659 5.579 5.579 0 012.38-4.149zm1-1.937h.007a.5.5 0 01.492.514.494.494 0 01-.521.486.5.5 0 01-.493-.515.5.5 0 01.514-.485zm-8.369 0h.007a.5.5 0 01.492.514.5.5 0 01-.521.486.506.506 0 01-.354-.157.491.491 0 01-.139-.358.5.5 0 01.513-.485z"
          data-name="Path 19485"
          transform="translate(0 .164)"
        ></path>
        <path
          d="M403.679 1566.5a1.743 1.743 0 00-1.283.525 5.642 5.642 0 00-5.494 1.488 5.76 5.76 0 00-.839 7.028.737.737 0 00.624.352h.009a.758.758 0 00.529-.213l.08-.077a.716.716 0 00.106-.916 4.075 4.075 0 01-.659-2.224 4.239 4.239 0 01.168-1.141 4.187 4.187 0 014.975-2.89 1.743 1.743 0 101.783-1.933zm-.507 1.751a.5.5 0 01.509-.482h.006a.5.5 0 01.329.863.508.508 0 01-.358.137.5.5 0 01-.486-.515z"
          data-name="Path 19486"
          transform="translate(.167 .505)"
        ></path>
        <path
          d="M386.616 1567.025a1.883 1.883 0 00-1.264-.525h-.135a1.744 1.744 0 00-1.122 3.021 1.672 1.672 0 001.236.464 1.786 1.786 0 001.775-1.552 4.115 4.115 0 01.945-.116h.03a4.174 4.174 0 014.172 4.141 4.085 4.085 0 01-.658 2.226.72.72 0 00.105.916l.085.082a.751.751 0 00.516.21h.019a.737.737 0 00.627-.352 5.76 5.76 0 00-.838-7.029 5.63 5.63 0 00-5.493-1.486zm-.766 1.243a.5.5 0 01-.52.452h-.006a.494.494 0 01-.491-.478.513.513 0 01.558-.476h.006a.466.466 0 01.331.156.48.48 0 01.122.346z"
          data-name="Path 19487"
          transform="translate(0 .505)"
        ></path>
        <path
          d="M400.057 1569.959a2 2 0 00.1 4h.033a2 2 0 001.931-2.067 2.028 2.028 0 00-2.064-1.933zm.084 1.473a.613.613 0 01.4 1.06.569.569 0 01-.438.165.614.614 0 01-.594-.632.606.606 0 01.623-.589z"
          data-name="Path 19488"
          transform="translate(.167 .505)"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
