import { APIBase } from "../../index";
import axios from "axios";
import { setupAxios } from "../../../../_metronic";
import store from "../../../../app/store/store";

const URL = "Store/Permissions/GetAllPermissions?usertype=5";

export const Permissions = () => {
  const config = {
    headers: {
      "content-type": "application/json", 
    },
  };

  setupAxios(axios, store);
  let request = axios.get(APIBase + URL, config);

  request.catch((err) => {
    console.log(err?.response?.data);
  });
  return request;
};
