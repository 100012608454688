import React, { lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CrumbRoute from "../../router/crumb-route";

const CRUD = lazy(() => import("./crud/index"));



export default function MembersPage() {


  return (
    <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/discount-operations" />
        }
      <Redirect exact={true} from="/discount-operations" to="/discount-operations/crud/list" />
      <CrumbRoute title="عمليات الخصومات" path="/discount-operations/crud" component={CRUD} />

    </Switch>
  );
}
