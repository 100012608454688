import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { IdentityAPI } from "../../API";

class ForgotPassword extends Component {
  state = { isRequested: false };

  render() {
    const { intl } = this.props;
    const { isRequested } = this.state;

    if (isRequested) {
      return <Redirect to="/auth" />;
    }

    return (
      <div
        style={{ direction: "rtl" }}
        className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper"
      >
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <h3>
                <FormattedMessage id="AUTH.FORGOT.TITLE" />
              </h3>
            </div>

            <Formik
              initialValues={{ Username: "" }}
              validate={(values) => {
                const errors = {};

                if (!values.Username) {
                  errors.Username = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                  });
                }

                return errors;
              }}
              onSubmit={async (values, { setStatus, setSubmitting }) => {
                setSubmitting(true);

                await IdentityAPI.User.ResetPassword(values.Username)
                  .then((s) => {
                    setSubmitting(false);
                    //  console.log("go to confirm");
                    this.props.history.push("/auth/confirm-reset-password", {
                      Username: values.Username,
                    });
                  })
                  .catch((e) => {
                    setStatus("اسم مستخدم غير صحيح");
                    setSubmitting(false);
                    // ErrorHandler(e, setErrors);
                  });
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} className="kt-form">
                  {status && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  )}

                  <div className="form-group">
                    <TextField
                      type="text"
                      label="اسم المستخدم"
                      margin="normal"
                      fullWidth={true}
                      name="Username"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Username}
                      helperText={touched.Username && errors.Username}
                      error={Boolean(touched.Username && errors.Username)}
                    />
                  </div>

                  <div className="kt-login__actions">
                    <Link to="/auth">
                      <button
                        type="button"
                        className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                      >
                        رجوع
                      </button>
                    </Link>

                    <button
                      type="submit"
                      className="btn btn-primary btn-elevate kt-login__btn-primary"
                      disabled={isSubmitting}
                    >
                      استرجاع
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
