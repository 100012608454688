import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { IdentityAPI } from "../../API";
import Axios from "axios";

class ConfirmLogin extends Component {
  state = { isRequested: false };

  render() {
    const { isRequested } = this.state;
    if (isRequested) {
      return <Redirect to="/auth" />;
    }

    return (
      <div
        style={{ direction: "rtl" }}
        className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper"
      >
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <h3>تاكيد تسجيل الدخول</h3>
            </div>

            <Formik
              initialValues={{ ConfirmCode: "" }}
              validate={(values) => {
                const errors = {};

                if (!values.ConfirmCode) {
                  errors.ConfirmCode = "يرجى ادخال كود التاكيد";
                }

                return errors;
              }}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                setSubmitting(true);
                debugger;
                IdentityAPI.User.ConfirmLogin(
                  this.props.pageData.Username,
                  values.ConfirmCode,
                  this.props.pageData.resend_token
                )
                  .then(({ data }) => {
                    debugger;
                    setSubmitting(false);
                    Axios.defaults.headers.common["Authorization"] =
                      "Bearer " + data.access_token;
                    this.props.login(data.access_token);
                    //  history.push("/auth/confirm-login");
                  })
                  .catch((e) => {
                    setStatus("كود التاكيد غير صحيح");
                    setSubmitting(false);
                    // ErrorHandler(e, setErrors);
                  });
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} className="kt-form">
                  {status && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  )}

                  <div className="form-group">
                    <TextField
                      label="كود التاكيد"
                      margin="normal"
                      fullWidth={true}
                      name="ConfirmCode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ConfirmCode}
                      helperText={touched.ConfirmCode && errors.ConfirmCode}
                      error={Boolean(touched.ConfirmCode && errors.ConfirmCode)}
                    />
                  </div>

                  <div className="kt-login__actions">
                    <Link to="/auth">
                      <button
                        type="button"
                        className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                      >
                        رجوع
                      </button>
                    </Link>

                    <button
                      type="submit"
                      className="btn btn-primary btn-elevate kt-login__btn-primary"
                      disabled={isSubmitting}
                    >
                      تاكيد
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(connect(null, auth.actions)(ConfirmLogin));
