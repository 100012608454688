import React, { Component } from "react";
import SettingsCard from "./settingsCard";

import settings_04 from "../../../../_metronic/_assets/images/settings_04.svg";
import settings_06 from "../../../../_metronic/_assets/images/settings_06.svg";
import settings_09 from "../../../../_metronic/_assets/images/settings_09.svg";
import {
  PermissionContainer,
  Permissions,
} from "../../../Components/PermissionManager";

export default class SettingsMenu extends Component {
  render() {
    return (
      <div className="row">
       
       <PermissionContainer any={[Permissions.Store.Edit]}>
          <SettingsCard
            imgSrc={settings_06}
            title={"بيانات المتجر"}
            subTitle={"تعديل بيانات المتجر الخاص بك"}
            path={"/settings/Store/Edit"}
            history={this.props.history}
          />
        </PermissionContainer>

        <PermissionContainer any={[Permissions.Identity.Users.View]}>
          <SettingsCard
            imgSrc={settings_04}
            title={"مستخدمي الموقع"}
            subTitle={"انواع و إعدادات المستخدمين"}
            path={"/settings/users"}
            history={this.props.history}
          />
        </PermissionContainer>

        <PermissionContainer any={[Permissions.Identity.Roles.View]}>
          <SettingsCard
            imgSrc={settings_06}
            title={"الصلاحيات والادوار"}
            subTitle={"مجموعة الصلاحيات والادوار"}
            path={"/settings/Permissions/list"}
            history={this.props.history}
          />
        </PermissionContainer>
      </div>
    );
  }
}
