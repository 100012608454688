import React, { Component } from "react";
import "./settingsCard.scss";

export default class SettingsCard extends Component {
  render() {
    return (
      <div
        className="col-3"
        onClick={() => {
          this.props.history.push(`${this.props.path}`);
        }}
      >
        <div className="card settingCard">
          <img src={this.props.imgSrc} />
          <b style={{ color: "#21294D" }}>{this.props.title} </b>
          <p style={{ color: "#787878" }}>{this.props.subTitle} </p>
        </div>
      </div>
    );
  }
}
