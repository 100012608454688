import React from "react";
import { createMuiTheme } from "@material-ui/core";
import {
  ThemeProvider as MuiThemeProvider,
  jssPreset,
  StylesProvider,
} from "@material-ui/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import { common } from "@material-ui/core/colors";

const theme = createMuiTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    direction: "rtl",
    typography: {
      fontFamily: ["TheSans-Plain"].join(","),
    },

    palette: {
      contrastThreshold: 3,
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#21294D",
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: "#0abb87",
        // dark: will be calculated from palette.primary.main,
        contrastText: common.white,
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: "#fd397a",
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true, // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1,
      },
    },
  }
);

export default function ThemeProvider(props) {
  const { children } = props;
  // Configure JSS
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
  return (
    <MuiThemeProvider theme={theme}>
      <StylesProvider jss={jss}>{children}</StylesProvider>
    </MuiThemeProvider>
  );
}
