import { shallowEqual, useSelector } from "react-redux";
import Permissions from "./Permissions";

function PermissionContainer({ children, ignoreIfNull, any }) {
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth?.user,
    }),
    shallowEqual
  );

  const Any = () => {
    return user?.permissions?.some(
      (item) =>
        any?.findIndex((q) => q?.toUpperCase() === item?.toUpperCase()) > -1
    );
  };

  if (ignoreIfNull && (any === undefined || any === null)) return children;
  else if (Any()) return children;

  return null;
}
const useAllowAccess = () => {
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth?.user,
    }),
    shallowEqual
  );

  return {
    AllowAccess: (any) => {
      return user?.permissions?.some(
        (item) =>
          any?.findIndex((q) => q?.toUpperCase() === item?.toUpperCase()) > -1
      );
    },
  };
};
export { PermissionContainer, Permissions, useAllowAccess };
