import * as Yup from "yup";
import * as Utilities from "../index";

Yup.addMethod(Yup.string, "validateSAID", function(errorMessage) {
  return this.test(`validate-said`, errorMessage, function(value) {
    const { path, createError } = this;
    return (
      !value ||
      Utilities.Validation.validateSAID(value) >= 0 ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.string, "SaPhoneNumber", function(errorMessage) {
  return this.test(`validate-sa-number`, errorMessage, function(value) {
    const { path, createError } = this;
    return (
      !value ||
      Utilities.Validation.validateSaNumber(value) ||
      createError({ path, message: errorMessage })
    );
  });
});

const EXCEL_DEFAULT_FILE_SIZE = 50000000;
const EXCEL_SUPPORTED_FORMATS = [
  "application/vnd.ms-excel",
  "application/msexcel",
  "application/x-msexcel",
  "application/x-ms-excel",
  "application/x-excel",
  "application/x-dos_ms_excel",
  "application/xls",
  "application/x-xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
];

const DEFAULT_FILE_SIZE = 1048576;
const SUPPORTED_Image_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
];


Yup.addMethod(Yup.mixed, "ExcelFileSize", function(
  errorMessage,
  size = EXCEL_DEFAULT_FILE_SIZE
) {
  return this.test(`validate-excel-file-size`, errorMessage, function(value) {
    const { path, createError } = this;

    if (value?.length) {
      for (let i in Array.from(value)) {
        let file = Array.from(value)[i];
        if (file && file.size >= size)
          return createError({ path, message: errorMessage });
      }
      return true;
    }

    return (
      !value ||
      (value && value.size <= size) ||
      createError({ path, message: errorMessage })
    );
  });
});


Yup.addMethod(Yup.mixed, "excelFormat", function(errorMessage) {
  return this.test(`validate-excel-format`, errorMessage, function(value) {
    const { path, createError } = this;

    if (value?.length) {
      for (let i in Array.from(value)) {
        let file = Array.from(value)[i];
        if (file && !EXCEL_SUPPORTED_FORMATS.includes(file.type))
          return createError({ path, message: errorMessage });
      }
      return true;
    }

    return (
      !value ||
      (value && EXCEL_SUPPORTED_FORMATS.includes(value.type)) ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.mixed, "fileSize", function(
  errorMessage,
  size = DEFAULT_FILE_SIZE
) {
  return this.test(`validate-file-size`, errorMessage, function(value) {
    const { path, createError } = this;

    if (value?.length) {
      for (let i in Array.from(value)) {
        let file = Array.from(value)[i];
        if (file && file.size >= size)
          return createError({ path, message: errorMessage });
      }
      return true;
    }

    return (
      !value ||
      (value && value.size <= size) ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.mixed, "fileSizeBig", function(
  errorMessage,
  size = 10485760
) {
  return this.test(`validate-file-size-big`, errorMessage, function(value) {
    const { path, createError } = this;

    if (value?.length) {
      for (let i in Array.from(value)) {
        let file = Array.from(value)[i];
        if (file && file.size >= size)
          return createError({ path, message: errorMessage });
      }
      return true;
    }

    return (
      !value ||
      (value && value.size <= size) ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.mixed, "imageFormat", function(errorMessage) {
  return this.test(`validate-image-format`, errorMessage, function(value) {
    const { path, createError } = this;

    if (value?.length) {
      for (let i in Array.from(value)) {
        let file = Array.from(value)[i];
        if (file && !SUPPORTED_Image_FORMATS.includes(file.type))
          return createError({ path, message: errorMessage });
      }
      return true;
    }

    return (
      !value ||
      (value && SUPPORTED_Image_FORMATS.includes(value.type)) ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.mixed, "filesLength", function(errorMessage, maxLenght = 20) {
  return this.test(`validate-file-length`, errorMessage, function(value) {
    const { path, createError } = this;

    return (
      !value?.length ||
      (value && value.length <= maxLenght) ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.string, "onlySpaces", function (errorMessage) {
  return this.test(`only-spaces`, errorMessage, function (value) {
    const { path, createError } = this;
    return (
      !value ||
      value.trim().length > 0  ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.string, "withoutSpaces", function (errorMessage) {
  return this.test(`without-spaces`, errorMessage, function (value) {
    const { path, createError } = this;
    return (
      !value ||
      !/\s/.test(value)  ||
      createError({ path, message: errorMessage })
    );
  });
});


Yup.addMethod(Yup.string, "onlyArabic", function (errorMessage) {
  return this.test(`only-arabic`, errorMessage, function (value) {
    const { path, createError } = this;
    const arabicLettersRegex = /^[\u0600-\u06FF\s]+$/;
  
    return (
      !value ||
      arabicLettersRegex.test(value)  ||
      createError({ path, message: errorMessage })
    );
  });
});



Yup.addMethod(Yup.string, "onlyEnglish", function (errorMessage) {
  return this.test(`only-english`, errorMessage, function (value) {
    const { path, createError } = this;
    const englishLettersRegex =/^[a-zA-Z]+$/;
  
    return (
      !value ||
      englishLettersRegex.test(value)  ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.string, "onlyEnglishWithNumbers", function (errorMessage) {
  return this.test(`only-english-with-numbers`, errorMessage, function (value) {
    const { path, createError } = this;
    const englishLettersRegex =/^[a-zA-Z0-9]+$/;
  
    return (
      !value ||
      englishLettersRegex.test(value)  ||
      createError({ path, message: errorMessage })
    );
  });
});




// Regular expression to match only English letters, numbers, and special characters
Yup.addMethod(Yup.string, "onlyEnglishWithAllowedCharacters", function (errorMessage) {
  return this.test(`only-english-with-allowed-characters`, errorMessage, function (value) {
    const { path, createError } = this;
    const englishLettersRegex =/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
  
    return (
      !value ||
      englishLettersRegex.test(value)  ||
      createError({ path, message: errorMessage })
    );
  });
});





export default Yup;
