import { IdentityAPIBase } from "../../index";
import axios from "axios";

const LOGIN_URL = "api/verify-password";

export const Login = (Username, Password) => {
  let request = axios.post(IdentityAPIBase + LOGIN_URL, {
    phoneNumber: Username,
    Password: Password,
  });

  request.catch((err) => {
    console.log(err.response);
  });
  return request;
};
