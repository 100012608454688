import moment from "moment";
import { toast } from "react-toastify";

export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}
export function GUIDV4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );
  axios.interceptors.response.use(
    (response) => {
      if (response?.data?.failed) return Promise.reject(response);
      return response;
    },
    (error) => {
      if (error?.config?.__isRetryRequest) return Promise.reject(error);

      if (!error?.config) error.config = {};
      error.config.__isRetryRequest = true;

      if (error?.response?.status == 401) {
        window.location.replace("/logout");
      } else if (error?.data?.failed) {
        toast(error?.data?.message, {
          type: "error",
        });
      }
      //  else if (error.response.status == 500) {
      //   window.location.replace("/dashboard");
      // }

      // whatever you want to do with the error
      return Promise.reject(error);
    }
  );
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
  try {
    localStorage.setItem(key, "");
    localStorage.setItem(key + "_expiresIn", "");
  } catch (e) {
    console.log(
      "removeStorage: Error removing key [" +
        key +
        "] from localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage(key) {
  const now = Date.now(); //epoch time, lets deal only with integer
  // set expiration for storage
  let expiresIn = localStorage.getItem(key + "_expiresIn");
  if (expiresIn == undefined || expiresIn == null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  } else {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (e) {
      console.log(
        "getStorage: Error reading key [" +
          key +
          "] from localStorage: " +
          JSON.stringify(e)
      );
      return null;
    }
  }
}
/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
  if (expires == undefined || expires == null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  }

  const now = Date.now(); //millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + "_expiresIn", schedule);
  } catch (e) {
    console.log(
      "setStorage: Error setting key [" +
        key +
        "] in localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}

export function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}
export function membersImageHandler(mainPhoto, manWoman,imageType) {
  let photo = toAbsoluteUrl("/media/users/default.jpg");
  photo = manWoman == false ? toAbsoluteUrl("/media/users/famale.png") : photo;
  photo = manWoman == true ? toAbsoluteUrl("/media/store/vector-shop-icon.png") : photo;
  photo = (imageType && imageType == "user") ? toAbsoluteUrl("/media/users/default.jpg")  : photo;
  photo = (imageType && imageType == "discount") ? toAbsoluteUrl("/media/store/discount.jpg")  : photo;
  photo = mainPhoto
    ? (validURL(mainPhoto) ? "" : "http://rfapp.alrajhifamily.net/") + mainPhoto
    : photo;

  return photo;
}

export function TrueFalse(item) {
  if (item == false || item == null) return false;

  return true;
}

export function TrueFalseMessage(item, trueMessage, falseMessage) {
  if (TrueFalse(item)) return trueMessage;

  return falseMessage;
}

export function FalseTrue(item) {
  if (item == true && item != null) return true;

  return false;
}

export function GetMaritalStatus(status) {
  if (status == "2") return "متزوج";
  else if (status == "3") return "مطلق";
  else if (status == "1") return "أعزب";
  else if (status == "4") return "أرمل";
  return "غير معرف";
}

export function GetRequestStatus(status) {
  if (status == 1) return "موافقة";
  else if (status == 2) return "مرفوض";
  else return "قيد الأنتظار";
}

export function GetRequestStatusColor(status) {
  if (status == 1) return "#F0F9ED";
  else if (status == 2) return "#FCECEC";
  else return "white";
}

export function GetMemberType(status) {
  if (status == 1) return "من العائلة";
  else if (status == 2) return "زوج/زوجة من  خارج العائلة";
  else if (status == 3) return "أبناء من خارج العائلة";
  else return "غير معرف";
}

export function FromFamily(status) {
  if (status == 1) return "نعم";
  else if (status == 2) return "لا";
  else if (status == 3) return "لا";
  else return "غير معرف";
}

export function GetGender(status) {
  if (status == true) return "ذكر";
  else if (status == false) return "أنثى";
  else return "غير معرف";
}

export function GetSibilingStatus(sibiling1, sibiling2) {
  if (
    sibiling1.motherNo == sibiling2.motherNo &&
    sibiling1.familyFatherNo == sibiling2.familyFatherNo
  )
    return "شقيق";
  else if (sibiling1.familyFatherNo == sibiling2.familyFatherNo)
    return "شقيق بالأب";
  else return "شقيق بالأم";
}

export function SmsCounter() {
  function SmsCounter() {}

  SmsCounter.gsm7bitChars =
    "@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\\\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà";

  SmsCounter.gsm7bitExChar = "\\^{}\\\\\\[~\\]|€";

  SmsCounter.gsm7bitRegExp = RegExp("^[" + SmsCounter.gsm7bitChars + "]*$");

  SmsCounter.gsm7bitExRegExp = RegExp(
    "^[" + SmsCounter.gsm7bitChars + SmsCounter.gsm7bitExChar + "]*$"
  );

  SmsCounter.gsm7bitExOnlyRegExp = RegExp(
    "^[\\" + SmsCounter.gsm7bitExChar + "]*$"
  );

  SmsCounter.GSM_7BIT = "GSM_7BIT";

  SmsCounter.GSM_7BIT_EX = "GSM_7BIT_EX";

  SmsCounter.UTF16 = "UTF16";

  SmsCounter.messageLength = {
    GSM_7BIT: 160,
    GSM_7BIT_EX: 160,
    UTF16: 70,
  };

  SmsCounter.multiMessageLength = {
    GSM_7BIT: 153,
    GSM_7BIT_EX: 153,
    UTF16: 67,
  };

  SmsCounter.count = function(text) {
    var count, encoding, length, messages, per_message, remaining;
    encoding = this.detectEncoding(text);
    length = text.length;
    if (encoding == this.GSM_7BIT_EX) {
      length += this.countGsm7bitEx(text);
    }
    per_message = this.messageLength[encoding];
    if (length > per_message) {
      per_message = this.multiMessageLength[encoding];
    }
    messages = Math.ceil(length / per_message);
    remaining = per_message * messages - length;
    if (remaining == 0 && messages == 0) {
      remaining = per_message;
    }
    return (count = {
      encoding: encoding,
      length: length,
      per_message: per_message,
      remaining: remaining,
      messages: messages,
    });
  };

  SmsCounter.detectEncoding = function(text) {
    switch (false) {
      case text.match(this.gsm7bitRegExp) == null:
        return this.GSM_7BIT;
      case text.match(this.gsm7bitExRegExp) == null:
        return this.GSM_7BIT_EX;
      default:
        return this.UTF16;
    }
  };
  return SmsCounter;
}

var persianNumbers = [
    /۰/g,
    /۱/g,
    /۲/g,
    /۳/g,
    /۴/g,
    /۵/g,
    /۶/g,
    /۷/g,
    /۸/g,
    /۹/g,
  ],
  arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];

export const convertToEnglishNumbers = (str) => {
  str = str?.toString();
  if (typeof str === "string") {
    for (var i = 0; i < 10; i++) {
      str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
    }
  }
  return str;
};

export function timestamp(datetime) {
  return datetime
    ? convertToEnglishNumbers(
        moment(datetime)
          .startOf("day")
          .utc(true)
          .format("X")
      )
    : "";
}

export const getSubdomain = () => {
  let host = window.location.host;
  let protocol = window.location.protocol;
  let parts = host.split(".");
  let subdomain = "";
  // If we get more than 3 parts, then we have a subdomain
  // INFO: This could be 4, if you have a co.uk TLD or something like that.
  
  if (parts.length >= 3) {
    subdomain = parts[0];
  }
  return subdomain;
};
