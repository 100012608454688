import { SET_BRANCH_DATA,SET_VIEW_BRANCH_LSIT } from "./StoresActions";

const initialState = {
    branchData : {},
    viewBranchList : false,
  };

  const branchDatatReducer = (state= initialState,action) => {
    switch (action.type) {
  
      case SET_BRANCH_DATA:
        return {
          ...state,
          branchData: action.payload
        };

        case SET_VIEW_BRANCH_LSIT:
          return {
            ...state,
            viewBranchList: action.payload
          };

        default:
            return state
    }
};  
  
export default branchDatatReducer;