import React from "react";

export const ErrorHandler = (e, callback, setStatus) => {
  debugger;
  if (e.response?.data?.status === 400 || e?.response?.status === 400) {
    if (callback) callback(e?.response?.data?.Data ?? {});
  }
  if (e.response?.data?.status === 406 || e?.response?.status === 406) {
    if (setStatus) setStatus(e?.response?.data?.errors ?? {});
  }
};

export const _RenderErrors = (errors) => {
  if (!errors) return "";
  return Array.isArray(errors) ? (
    errors.map((err, index) => (
      <span key={index} style={{ display: "block" }}>
        - {err}
      </span>
    ))
  ) : (
    <span style={{ display: "block" }}>- {errors}</span>
  );
};
