import { APIBase } from "../../index";
import axios from "axios";
import { setupAxios } from "../../../../_metronic";
import store from "../../../../app/store/store";

const URL = "Store/Roles/GetRoleById/";

export const Details = (id) => {
  // axios.get(APIBase + "User").catch((err) => console.log(err));
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  setupAxios(axios, store);

  let request = axios.get(APIBase + URL + id, config);

  request.catch((err) => {
    console.log(err);
  });
  return request;
};
