import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";
import { PermissionContainer } from "../../../app/Components/PermissionManager";

export default class MenuSubmenu extends React.Component {
  render() {
    const { item, currentUrl, layoutConfig } = this.props;

    return (
      <ul className="kt-menu__subnav">
        {item.submenu.map((child, index) => (
          <React.Fragment key={index}>
            {child.section && (
              <MenuSection
                item={child}
                parentItem={item}
                currentUrl={currentUrl}
              />
            )}

            {child.separator && (
              <MenuItemSeparator
                item={child}
                parentItem={item}
                currentUrl={currentUrl}
              />
            )}

            {child.title && (
              <PermissionContainer {...child.permission}>
                <MenuItem
                  item={child}
                  parentItem={item}
                  currentUrl={currentUrl}
                  layoutConfig={layoutConfig}
                />
              </PermissionContainer>
            )}
          </React.Fragment>
        ))}
      </ul>
    );
  }
}
