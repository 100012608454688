import { APIBase } from "../../index";
import axios from "axios";
import { setupAxios } from "../../../../_metronic";
import store from "../../../store/store";

const URL = "SuperAdmin/Users/ChangePassword/";

export const ChangePassword = (data) => {
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  setupAxios(axios, store);

  let request = axios.post(APIBase + URL, data, config);
  return request;
};
