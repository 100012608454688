import { IdentityAPIBase } from "../../index";
import axios from "axios";

const CONFIRM_LOGIN_URL = "connect/token";

export const ConfirmLogin = (Username, ConfirmCode, resend_token) => {
  const params = new URLSearchParams();
  params.append("phone_number", Username);
  params.append("verification_token", ConfirmCode);
  params.append("resend_token", resend_token);
  params.append("scope", "StoreScope");
  params.append("grant_type", "phone_number_token");
  params.append("client_id", "pwd.sela.store");
  params.append("client_secret", "513636EZ-F270-4058-80CA-1C89C192F69A");

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  let request = axios.post(IdentityAPIBase + CONFIRM_LOGIN_URL, params, config);

  request.catch((err) => {
    console.log(err);
  });
  return request;
};
