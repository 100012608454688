import { APIBase } from "../../index";
import axios from "axios";
import { setupAxios } from "../../../../_metronic";
import store from "../../../../app/store/store";

const URL = "SuperAdmin/Users/GetUserById/";

export const Details = (id) => {
  setupAxios(axios, store);
  let request = axios.get(APIBase + URL + id);
  return request;
};
