import * as React from "react";
import * as FontIcon from "react-icons/fa";
import * as CSS from "csstype";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  AntDesign,
  Entypo,
  EvilIcons,
  Feather,
  FontAwesome,
  FontAwesome5,
  Ionicons,
} from "./_IconListItem";
import "./icon.scss";
import { TreeIcon, TreeGroupIcon } from "./SVG";
// @ts-ignore
interface IconProps {
  iconName: any;
  size?: number;
  color?: string;
  iconType?: string;
}

const Icon: React.FC<IconProps> = ({ iconType, iconName, size, color }) => {
  let IconType = null;
  if (iconType?.toUpperCase() == "MaterialCommunityIcons"?.toUpperCase())
    IconType = MaterialCommunityIcons;
  if (iconType?.toUpperCase() == "MaterialIcons"?.toUpperCase())
    IconType = MaterialIcons;
  if (iconType?.toUpperCase() == "AntDesign"?.toUpperCase())
    IconType = AntDesign;
  if (iconType?.toUpperCase() == "Entypo"?.toUpperCase()) IconType = Entypo;
  if (iconType?.toUpperCase() == "EvilIcons"?.toUpperCase())
    IconType = EvilIcons;
  if (iconType?.toUpperCase() == "Feather"?.toUpperCase()) IconType = Feather;
  if (iconType?.toUpperCase() == "FontAwesome"?.toUpperCase())
    IconType = FontAwesome;
  // if (iconType?.toUpperCase() == "FontAwesome5"?.toUpperCase()) IconType = FontAwesome5;
  if (iconType?.toUpperCase() == "Ionicons"?.toUpperCase()) IconType = Ionicons;
  if (
    iconType?.toUpperCase() == "CustomIcon"?.toUpperCase() &&
    iconName?.toUpperCase() == "TreeIcon".toUpperCase()
  )
    return <TreeIcon fill={color} size={size} />;
  if (
    iconType?.toUpperCase() == "CustomIcon"?.toUpperCase() &&
    iconName?.toUpperCase() == "TreeGroupIcon".toUpperCase()
  )
    return <TreeGroupIcon fill={color} size={size} />;

  if (IconType == null) return <div></div>;
  return <IconType name={iconName} size={size} color={color} />;
};

export { Icon };
